import './scss/main.scss'

import $ from 'jquery'
import 'owl.carousel'
import baguetteBox from 'baguettebox.js'
import WOW from 'wow.js'
import 'jquery-parallax.js'



document.addEventListener('DOMContentLoaded', function () {

    new WOW().init();

//animte scroll address

    let adrressBtn = document.querySelector('.main-header_address')

    adrressBtn.addEventListener('click', function(e) {

        e.preventDefault()

        document.querySelector('#map_script').scrollIntoView({
            behavior: 'smooth'
        });

    });

// button back to top

    let btnTop  = document.querySelector('.btn-top')

    btnTop.addEventListener('click', function() {

        document.querySelector('body').scrollIntoView({
            behavior: 'smooth'
        });

    })

    window.addEventListener('scroll', function () {
        if (document.documentElement.scrollTop > 700) {
            btnTop.classList.add('btn-show')
        } else {
            btnTop.classList.remove('btn-show')
        }
    })


// slider
    let owl = $('.owl-carousel').owlCarousel({
        loop: true,
        dots: true,
        nav: false,
        items: 1,
        margin: 30,
        autoplay: true,
        autoplayTimeout:7000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        lazyLoad: true,
    })

    owl.on('changed.owl.carousel', function(e) {
        owl.trigger('stop.owl.autoplay');
        owl.trigger('play.owl.autoplay');
    });

    $('.owl-dot').attr('aria-label', 'переключение слайда')




//parallax
    $('.choice').parallax({
        imageSrc: './assets/img/bg/bg-choice.jpg',
        speed: 0.3,
    });

    $('.parallax-window').parallax({
        imageSrc: './assets/img/bg/bg-smiles.jpg',
        speed: 0.3,
    });

    $('.advantages_inner').parallax({
        imageSrc: './assets/img/bg/bg-advantages.jpg',
        speed: 0.3,
    });

// gallery
    baguetteBox.run('.gallery');
    baguetteBox.run('.gallery-full');
    baguetteBox.run('.layout-aside_licences-list');

//preloader
    $('#status').fadeOut();
    $('#preloader').delay(350).fadeOut('slow');
    $('body').delay(350).css({'overflow':'visible'});

// nav-mobile
    let hamburger = document.querySelector('.hamburger');
    let menu = document.querySelector('.main-header_menu-list');
    let body = document.querySelector('body');


    hamburger.addEventListener('click', ()=>{
        hamburger.classList.toggle('is-active')
        body.classList.toggle('no-scroll')
        menu.classList.toggle('mobile-menu')
    })

    window.addEventListener('resize', ()=>{
        if(document.documentElement.clientWidth>700){
            hamburger.classList.remove('is-active');
            menu.classList.remove('mobile-menu')
            body.classList.remove('no-scroll')
        }
    })


});





